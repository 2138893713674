<template lang="">
    <div :class="renderClass()">
        <ckeditor
            :ref="computedData"
            v-model="computedData"
            :editor="editor"
            :config="editorConfig"
            :disabled="isReadOnly"
            @ready="onReady"
        />
    </div>
</template>

<script>
import * as ImageAPI from '../../../api/imageAPI';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/ja';

function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return uploadAdapter(loader);
    };
}

function uploadAdapter(loader) {
    return {
        upload: () => {
            return new Promise((resolve, reject) => {
                const body = new FormData();
                loader.file.then((file) => {
                    body.set('image', file);
                    ImageAPI.upload(body, 1000)
                        .then((res) => {
                            resolve({
                                default: res.results.object.url,
                            });
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            });
        },
    };
}

export default {
    props: {
        data: { type: String },
        isReadOnly: { type: Boolean },
    },
    components: {
        ckeditor: CKEditor.component,
    },
    data() {
        return {
            editor: DecoupledEditor,
            editorConfig: {
                toolbar: {
                    items: [
                        'heading',
                        '|',
                        'fontColor',
                        'fontBackgroundColor',
                        '|',
                        'bold',
                        'italic',
                        'underline',
                        'alignment',
                        '|',
                        'outdent',
                        'indent',
                        'bulletedList',
                        'numberedList',
                        '|',
                        'insertTable',
                        'link',
                        'uploadImage',
                        'blockQuote',
                    ],
                    shouldNotGroupWhenFull: true,
                },
                language: 'ja',
                extraPlugins: [uploadPlugin],
                removePlugins: ['ImageToolbar', 'TableToolbar'],
                mediaEmbed: { previewsInData: true },
                link: {
                    addTargetToExternalLinks: true,
                },
            },
        };
    },
    computed: {
        computedData: {
            get() {
                return this.data;
            },
            set(value) {
                this.$emit('update:data', value);
            },
        },
    },
    methods: {
        renderClass() {
            if (this.isReadOnly) {
                return 'my-editor read-only';
            } else {
                return 'my-editor ck-body-wrapper';
            }
        },

        onReady(editor) {
            const toolbarElement = editor.ui.view.toolbar.element;
            // Insert the toolbar before the editable area.
            editor.ui.getEditableElement().parentElement.insertBefore(toolbarElement, editor.ui.getEditableElement());

            if (this.isReadOnly) {
                toolbarElement.style.display = 'none';
            }
        },
    },
};
</script>

<style lang="scss">
.ck-body-wrapper {
    z-index: 1300 !important;
}

.my-editor {
    --ck-z-default: 100;
    --ck-z-modal: calc(var(--ck-z-default) + 999);

    & .ck.ck-content {
        min-height: 100px;
        max-height: 500px;
        overflow: scroll;
        margin-top: -1px;
        padding: 0px 20px;
        line-height: 1.4;
        border-width: 0px 1px 1px;
        border-style: solid;
        border-color: #ccced1;
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;

        & ul,
        & ol {
            padding-left: 20px;
        }

        & .image {
            width: fit-content;
            height: auto;
        }

        & .table {
            width: 100%;
        }
    }

    &.read-only {
        & .ck.ck-content {
            min-height: unset;
            max-height: 100%;
            margin-top: 0px;
            border: none;
            padding: 0px;
            overflow: unset;
            word-break: break-word;
        }

        & .ck.ck-editor__editable_inline > :first-child {
            margin: 0;
        }

        & .ck .ck-widget__selection-handle {
            display: none;
        }
    }
}
</style>
